<template>
  <div class="pa-5">

    <template>
      <v-row>
        <v-col
            cols="8"
            sm="8"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >

                <v-text-field
                    label="Название"
                    v-model="name"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>

              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Культура ID"
                    v-model="culture_id"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Возраст"
                    v-model="age"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <br><br><br>

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-btn
                    @click="addShow"
                    color="blue"
                    dark
                >
                  Добавить
                </v-btn>

              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                {{result}}
              </v-col>
            </v-row>



            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Автор"
                    v-model="author"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Жанр"
                    v-model="genre"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-textarea
                    outlined
                    name="Описание"
                    label="OОписание"
                    v-model="description"
                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Путь к картинке"
                    v-model="picture"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Ютуб"
                    v-model="video"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Картинок всего"
                    v-model="pictures_count"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Статус"
                    v-model="tag_status_id"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Длительность"
                    v-model="time"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    label="Ссылка"
                    v-model="link"
                    required
                    hide-details="auto"
                    prepend-icon="mdi-script-text-outline"
                ></v-text-field>
              </v-col>
            </v-row>









          </v-container>




        </v-col>

      </v-row>



      <v-btn @click="addShow">Добавить</v-btn>
      <hr>
      {{result}}

    </template>











  </div>
</template>

<script>

export default {
  name: "ShowAdd",

  data: () => ({

    valid: true,
    show_id:0,

    name:"",
    author:"",
    genre:"",
    description:"",
    picture:"",
    video:"",
    pictures_count:0,

    tag_status_id:70,
    time:"",
    link:"",
    age:"",
    culture_id: 0,

    result:"",

  }),

  // computed: {
  //
  //   layout_id: function () {
  //
  //     if (this.selected>-1){
  //       return this.list[this.selected].id;
  //     }
  //     return false;
  //   },
  //
  // },

  created() {

    if (this.$store.getters.isAuthenticated){

      //this.loadShows();
      //this.loadLayouts();

    }


  },


  methods:{

    addShow(){

      // TODO заблочить кнопку отправки

      let post_data = {

        id: 0,

        name:           this.name,
        author:         this.author,
        genre:          this.genre,
        description:    this.description,
        picture:        this.picture,
        video:          this.video,
        pictures_count: this.pictures_count,
        tag_status_id:  this.tag_status_id,
        time:           this.time,
        link:           this.link,
        age:            this.age,
        culture_id:     this.culture_id
      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addShow(post_data);

      pr2.then( data =>{

        console.log(data);

        this.result = this.name+ " - Спектакль "+data+" добавлен"

        // resp : id only
        //
        // this.layouts = data;
        // this.layouts_ready = true;
      })


    },




  }
}
</script>

<style scoped>

</style>
